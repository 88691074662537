import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { debounce } from "lodash"
import { InputBase, CircularProgress, ClickAwayListener, Typography } from "@material-ui/core"
import { Close as CloseIcon, Search as SearchIcon } from "react-ionicons"

// import app components
import Edges from "components/edges"
import useFetch from "utils/useFetch"
import { useStore } from "store"
import * as theme from "theme"

export const menuBreakpoint = 1120

const Search = (props) => {
  const { position } = props

  const [
    {
      appState: { scrollLock, search: active }
    },
    dispatch
  ] = useStore()

  const [search, setSearch] = useState("")
  const [searchResults, setSearchResults] = useState(null)

  const endpoint = `${process.env.GATSBY_WP}/wp-json/wp/v2/custom_search`

  const searchFieldInputRef = (input) => input && active && input.focus()

  const [executeSearch, { data, loading, error }] = useFetch(endpoint)

  const delayedFetch = useRef(
    debounce((args) => executeSearch({ ...args, language: process.env.GATSBY_ACTIVE_LANGUAGE.toLowerCase() }), 500)
  ).current

  useEffect(() => {
    data && setSearchResults(JSON.parse(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    !search && setSearchResults(null)

    if (search && !scrollLock) {
      dispatch({ type: "SET_SCROLL_LOCK", payload: true })
    } else if (!search && scrollLock) {
      dispatch({ type: "SET_SCROLL_LOCK", payload: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const onChange = (e) => {
    setSearch(e.target.value)
    e.target.value && delayedFetch({ s: e.target.value, language: process.env.GATSBY_ACTIVE_LANGUAGE.toLowerCase() })
  }

  const handleEscapeSearch = () => {
    setSearch("")
    setSearchResults(null)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleEscapeSearch}>
        <SearchBar position={position} active={!!search} search={search}>
          <StyledEdges size="lg">
            <SearchButton aria-label="Search">
              <SearchIcon />
            </SearchButton>
            <StyledTextField
              inputRef={searchFieldInputRef}
              value={search}
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? search !== "" &&
                    executeSearch({ s: e.target.value, language: process.env.GATSBY_ACTIVE_LANGUAGE.toLowerCase() })
                  : e.key === "Escape" && handleEscapeSearch
              }
              onChange={(e) => onChange(e)}
              fullWidth
              placeholder="Search..."
              aria-label="Search"
            />
            {search && !loading && (
              <CloseButton onClick={handleEscapeSearch}>
                <CloseIcon fontSize="40px" />
              </CloseButton>
            )}
            {loading && <CircularProgress size={18} className="loader" color="inherit" />}
          </StyledEdges>

          <SearchModal {...{ searchResults }} open={searchResults} onClose={handleEscapeSearch} fullWidth maxWidth="sm">
            {error && <Item style={{ padding: "20px" }}>Something went wrong.</Item>}

            {!loading && searchResults && (
              <>
                {searchResults.length > 0 ? (
                  <Results>
                    {searchResults.map((o) => {
                      return (
                        <Item key={o.id} component="div">
                          <StyledLink to={o.link} onClick={handleEscapeSearch}>
                            {Parser(o.title)}
                          </StyledLink>
                        </Item>
                      )
                    })}
                  </Results>
                ) : (
                  <NoResult component="div">No Results</NoResult>
                )}
              </>
            )}
          </SearchModal>
        </SearchBar>
      </ClickAwayListener>
    </>
  )
}

const SearchBar = styled.div`
  position: ${(props) => props.position};
  z-index: 30;
  top: 102px;
  left: 0;
  width: 100%;
  height: 50px;
  background: rgba(43, 49, 43, 1);
  color: white;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  margin-top: 4px;

  @media (min-width: ${menuBreakpoint}px) {
    top: 102px;
    height: 40px;
    margin-top: 0px;
  }

  .loader {
    position: absolute;
    right: 0;
  }

  ${({ active }) =>
    active &&
    css`
      position: fixed;
      top: 104px;
      background: rgba(19, 19, 19, 0.95);
      overflow: unset;
      height: 50px;

      @media (min-width: ${menuBreakpoint}px) {
        top: 102px;
        height: 40px;
      }
    `}
`

const StyledTextField = styled(InputBase)`
  color: #fff;

  input {
    ::placeholder {
      color: #fff;
      opacity: 1;
    }
  }
`

const Results = styled.div`
  max-height: 80vh;
  overflow: auto;
`

const icon = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

const SearchButton = styled.button`
  ${icon}
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg {
    fill: white;
  }
`

const CloseButton = styled.button`
  ${icon}
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg {
    fill: white;
  }
`

const StyledEdges = styled(Edges)`
  position: relative;
  display: flex;
`

const SearchModal = styled.div`
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(100vh - 102px);

  @media (min-width: ${menuBreakpoint}px) {
    height: calc(100vh - 142px);
  }
  background: #fff;

  ${({ open }) =>
    open
      ? css`
          transform: scaleY(100%)
          display: block;
          opacity: 1;
          pointer-events: all;
        `
      : css`
          transform: scaleY(0%);
          opacity: 0;
          pointer-events: none;
        `}
`

const Item = styled(Typography)`
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: ${theme.colors.primary};

  a {
    color: inherit;
  }

  &:hover {
    a {
      text-decoration: underline;
    }
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  text-decoration: none;
  text-align: center;
  color: ${theme.colors.primary};
`

const NoResult = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: ${theme.colors.primary};
`

export default Search
