import React, { useEffect } from "react"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import { Search as SearchIcon } from "react-ionicons"

// import material ui
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import LinearProgress from "@material-ui/core/LinearProgress"
import IconButton from "@material-ui/core/IconButton"

// import app components
import Link from "components/link"
import MediaQuery from "components/mediaQuery"
import Edges from "components/edges"
import MegaNav from "components/menu/MegaNav"
import MegaNavInternational from "components/menu/MegaNavInternational"
import MobileMenu from "components/menu/MobileMenu"
import UtilityMenu from "components/menu/UtilityMenu"
import Logo from "../../../static/RealCedar_simple-color.svg"
import LanguageSwitcher from "components/languageSwitcher"
import RoundedButton from "components/menu/RoundedButton"
import Search from "components/search"

import { useStore } from "store"
import { userServices } from "services"
import * as theme from "theme"
import getIp from "utils/getIp"

export const menuBreakpoint = 1120

const Header = (props) => {
  const { path, data, uri } = props

  const [
    {
      appState: { search, progress, menu, geoInformation, menuItemActive }
    },
    dispatch
  ] = useStore()

  let hideSearch = true

  useEffect(() => {
    const array = path && path.split("/")

    // why western red cedar shouldn't be treated as it's own section
    // because it is a sub section of all the major once like siding, decking etc.
    if (
      array &&
      array.length > 2 &&
      array[1] !== "why-western-red-cedar" &&
      array[1] !== "pourquoi-du-cedre-rouge" &&
      array[1] !==
        "%E3%81%AA%E3%81%9C%E3%82%A6%E3%82%A7%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%AC%E3%83%83%E3%83%89%E3%82%B7%E3%83%80%E3%83%BC%E3%81%8B%EF%BC%9F" &&
      array[1] !== "%E4%B8%BA%E4%BB%80%E4%B9%88%E9%80%89%E6%8B%A9%E8%A5%BF%E9%83%A8%E7%BA%A2%E6%9F%8F"
    ) {
      dispatch({ type: "SET_ACTIVE_SECTION", payload: decodeURI(array[1]) })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  useEffect(() => {
    const loadGeoInformation = async () => {
      const ip = await getIp()

      if (ip) {
        const result = await userServices.loadGeoInformation(ip)
        if (result) {
          dispatch({ type: "SET_GEO_INFORMATION", payload: result })
        }
      }
    }

    if (!geoInformation) {
      process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && loadGeoInformation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoInformation])

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
    dispatch({ type: "SET_SEARCH", payload: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  const handleMenuToggle = () => {
    if (menu) {
      dispatch({ type: "SET_MENU_ITEM", payload: null })
    }

    dispatch({ type: "TOGGLE_MENU" })
  }

  const handleLogoClick = () => {
    dispatch({ type: "SET_MENU", payload: false })

    dispatch({
      type: "SET_ACTIVE_SECTION",
      payload: null
    })
  }

  const handleSearchClick = () => {
    typeof window !== `undefined` && window.scrollTo(0, 0)
    dispatch({ type: "SET_MENU", payload: false })
    dispatch({ type: "SET_SEARCH", payload: !search })
  }

  return (
    <>
      <StyledAppBar position={"fixed"} data-scroll-header>
        <StyledToolbar>
          <TopMenu minWidth={menuBreakpoint}>
            <Edges size="lg">
              <TopMenuInner>
                <UtilityMenu />
                <LanguageSwitcher />
              </TopMenuInner>
            </Edges>
          </TopMenu>

          <Edges size="lg">
            <LogoContainer to="/" onClick={handleLogoClick} aria-label="Go back to Homepage">
              <Logo />
            </LogoContainer>

            <Navigation>
              <MediaQuery minWidth={menuBreakpoint}>
                <MainMenu>
                  {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" ? (
                    <MegaNav
                      name="main"
                      path={path}
                      hide={
                        data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                          ? data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                          : false
                      }
                    />
                  ) : (
                    <>
                      <MegaNavInternational
                        name="main"
                        path={path}
                        hide={
                          data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                            ? data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                            : false
                        }
                      />
                      <MegaNavInternational
                        name="about"
                        path={path}
                        hide={
                          data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                            ? data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                            : false
                        }
                      />
                    </>
                  )}

                  {/* <MegaNav
                    name="about"
                    path={path}
                    hide={
                      data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                        ? data?.wpPage?.templateWorkingWithCedar?.wwcHideSearch
                        : false
                    }
                  /> */}
                  {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && (
                    <>
                      <IconButton aria-label="Search" onClick={handleSearchClick}>
                        <SearchIcon />
                      </IconButton>
                      <FindARetailer>
                        <RoundedButton uri={uri} />
                      </FindARetailer>
                    </>
                  )}
                </MainMenu>
              </MediaQuery>

              <MediaQuery maxWidth={menuBreakpoint - 1}>
                {/* <LanguageSwitcher /> */}
                <IconButton aria-label="Search" onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
                <HamburgerMenuContainer>
                  <HamburgerMenu
                    color={theme.colors.primary}
                    isOpen={menu}
                    width={18}
                    height={15}
                    strokeWidth={1}
                    menuClicked={handleMenuToggle}
                  />
                </HamburgerMenuContainer>
              </MediaQuery>
            </Navigation>
          </Edges>
        </StyledToolbar>

        <MediaQuery maxWidth={menuBreakpoint - 1}>
          <FindARetailer mobileProp>
            <RoundedButton />
          </FindARetailer>
        </MediaQuery>
      </StyledAppBar>

      <MediaQuery maxWidth={menuBreakpoint - 1}>
        <MobileMenu />
      </MediaQuery>

      {progress && <StyledLinearProgress color="primary" />}

      {(search || hideSearch !== true) &&
        typeof hideSearch !== "undefined" &&
        process.env.GATSBY_ACTIVE_LANGUAGE === "EN" &&
        path !== "/ualfjsdopslapwdfvsacf/" && <Search position={hideSearch && search ? "relative" : "absolute"} />}

      <ClickAwayListener
        active={menuItemActive}
        onClick={() =>
          dispatch({
            type: "SET_MENU_ITEM",
            payload: null
          })
        }
      />
    </>
  )
}

const StyledAppBar = styled(AppBar)`
  && {
    background: #fff;
    box-shadow: none;
  }
`

const StyledToolbar = styled(Toolbar)`
  padding: 0;
  flex-wrap: wrap;

  > div {
    display: flex;
  }
`

const TopMenu = styled(MediaQuery)`
  width: 100%;
  position: relative;
  z-index: 40;
  background: linear-gradient(270deg, #f2f2f2 0.01%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
`

const TopMenuInner = styled.div`
  display: flex;
  justify-content: flex-end;
`

const LogoContainer = styled(Link)`
  position: absolute;
  z-index: 40;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 10px 10px 0;
  svg {
    width: 125px;
    height: 52px;
  }
`

const Navigation = styled.nav`
  flex: 1;
  > div {
    display: flex;
    justify-content: flex-end;
    height: 64px;
  }
`

const MainMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const FindARetailer = styled.div`
  margin-left: ${(props) => (props.mobileProp ? "10px" : "20px")};
  padding-bottom: ${(props) => (props.mobileProp ? "10px" : "0px")};
`

const HamburgerMenuContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
`

const ClickAwayListener = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? "all" : "none")};
`

export default Header
